<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>支付结算</el-breadcrumb-item>
      <el-breadcrumb-item>账单日汇总</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            placeholder="请选择"
            style="width: 150px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >创建时间：<el-date-picker
            v-model="startTime"
            type="date"
            size="small"
            value-format="timestamp"
            placeholder="选择日期"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="endTime"
            type="date"
            size="small"
            value-format="timestamp"
            placeholder="选择日期"
            @change="inTimeEndchange"
          >
          </el-date-picker
        ></label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="orderTotalReportcx"
          >查询</el-button
        >
        <el-button size="small" @click="orderTotalReportcz()">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="exportOrder"
        >导出</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="totalTime"
            label="日期"
          >
            <template slot-scope="scope">
              <span v-if="!scope.row.pushId">{{
                conversionTime1(scope.row.totalTime / 1000)
              }}</span>
              <span v-else style="font-weight: 600; font-size: 15px"
                >合计金额</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="totalMoney"
            label="实收总金额"
            width="90"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.totalMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="cashMoney"
            label="现金总金额"
            width="90"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.cashMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="实际优惠抵扣总额"
            width="125"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.couponMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="ccbMoney"
            label="建行聚合收款总金额"
            width="135"
          >
            <template slot-scope="scope">
              <span
                an
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.ccbMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="alipayMoney"
            label="支付宝收款总金额"
            width="125"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.alipayMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="wxMoney"
            label="微信收款总金额"
            width="120"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.wxMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="hzbankMoney"
            label="杭州银行总金额"
            width="120"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.hzbankMoney ? scope.row.hzbankMoney : 0 }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="hzcityMoney"
            label="城市大脑总金额"
            width="120"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.hzcityMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="hzetcMoney"
            label="杭州城市大脑ETC总金额"
            width="150"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.hzetcMoney ? scope.row.hzetcMoney : 0 }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="icbcMoney"
            label="工商银行收款总金额"
            width="150"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.icbcMoney ? scope.row.icbcMoney : 0 }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="valueCardMoney"
            label="储值车收款总金额"
            width="150"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{
                  scope.row.valueCardMoney ? scope.row.valueCardMoney : 0
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="etcMoney"
            label="ETC"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ scope.row.etcMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column header-align="left" align="left" label="差额">
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.pushId ? 'font-weight: 700;font-size: 16px' : ''
                "
                >{{ jsze(scope.row) }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.pushId"
                type="text"
                @click="isShowQueryDialog(scope)"
                size="mini"
                icon="el-icon-share"
                >查看明细</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 查看当日明细 -->
    <el-dialog
      title="订单详细"
      :visible.sync="isShowQuery"
      width="40%"
      v-el-drag-dialog
    >
      <el-table :data="tableDatatk" style="width: 100%">
        <el-table-column prop="payOrderNo" label="订单编号"></el-table-column>
        <el-table-column
          prop="orderPayMoney"
          label="订单金额"
        ></el-table-column>
        <el-table-column prop="payTimeDate" label="订单日期">
          <template slot-scope="scope">
            <span>{{ conversionTime(scope.row.payTimeDate / 1000) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { orderTotalReport, exportOrderCollectDays } from '@/api/common.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
export default {
  data() {
    return {
      loading: false,
      isShowData: false,
      isShowQuery: false,
      tableData: [],
      tableDatatk: [],
      startTime: undefined, // 起始
      endTime: undefined, // 截至
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pkId: undefined, // 停车场id
        startTime: undefined, // 起始
        endTime: undefined, // 截至
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    jsze(item) {
      // 返回金额为null  处理
      if (!item.hzbankMoney) {
        item.hzbankMoney = 0
      }
      if (!item.icbcMoney) {
        item.icbcMoney = 0
      }
      if (!item.valueCardMoney) {
        item.valueCardMoney = 0
      }
      return (
        Number(item.totalMoney) -
        (Number(item.cashMoney) +
          Number(item.couponMoney) +
          Number(item.hzcityMoney) +
          Number(item.hzbankMoney) +
          Number(item.ccbMoney) +
          Number(item.alipayMoney) +
          Number(item.wxMoney) +
          Number(item.etcMoney) +
          Number(item.hzetcMoney) +
          Number(item.icbcMoney) +
          Number(item.valueCardMoney))
      ).toFixed(2)
    },
    orderTotalReport() {
      // 列表
      this.query.startTime = this.startTime
      this.query.endTime = this.endTime
      if (this.query.startTime && !this.query.endTime) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.startTime && this.query.endTime) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.startTime || this.query.endTime) {
        this.query.startTime = (this.query.startTime / 1000).toFixed(0)
        this.query.endTime = (this.query.endTime / 1000).toFixed(0)
      }
      orderTotalReport(this.query).then((res) => {
        this.tableData = res.data.data.list
        if (this.tableData.length) {
          this.tableData.push({
            pushId: true,
            ccbMoney: this.tableData
              .map((item) => item.ccbMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            alipayMoney: this.tableData
              .map((item) => item.alipayMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            wxMoney: this.tableData
              .map((item) => item.wxMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            totalMoney: this.tableData
              .map((item) => item.totalMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            cashMoney: this.tableData
              .map((item) => item.cashMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            couponMoney: this.tableData
              .map((item) => item.couponMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            hzcityMoney: this.tableData
              .map((item) => item.hzcityMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            etcMoney: this.tableData
              .map((item) => item.etcMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            hzetcMoney: this.tableData
              .map((item) => item.hzetcMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            hzbankMoney: this.tableData
              .map((item) => item.hzbankMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            icbcMoney: this.tableData
              .map((item) => item.icbcMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
            valueCardMoney: this.tableData
              .map((item) => item.valueCardMoney)
              .reduce((a, b) => a + b)
              .toFixed(2),
          })
        }
        this.total = res.data.data.total
        this.isShowData = true
        this.loading = false
      })
    },
    orderTotalReportcx() {
      // 查询
      this.query.current = 1
      this.orderTotalReport()
    },
    orderTotalReportcz() {
      // 重置
      this.query.pkId = this.PkInfoSelect[0].id
      this.startTime = undefined
      this.endTime = undefined
      this.orderTotalReport()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pkId = this.PkInfoSelect[0].id
          this.orderTotalReport()
        }
      })
    },
    isShowQueryDialog(scope) {
      // 查询当日订单
      this.$router.push({
        path: 'Neworder',
        query: { totalTime: scope.row.totalTime, pkId: this.query.pkId },
      })
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.orderTotalReport()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.orderTotalReport()
    },
    exportOrder() {
      // 导出
      if (this.startTime && this.endTime) {
        const obj = {
          startTime: this.startTime / 1000, // 起始
          endTime: this.endTime / 1000, // 截至
        }
        exportOrderCollectDays(obj, { pk_id: this.query.pkId }).then((res) => {
          if (res.data.code === '200') {
            window.open(res.data.data)
          }
        })
      } else {
        this.$message.warning('请选择开始和结束时间查询后再点击导出数据')
      }
    },
    // 结束时间选择
    inTimeEndchange() {
      if (this.startTime === null || this.startTime === undefined) {
        this.endTime = null
        return this.$message.warning('请选择开始时间')
      } else if (this.endTime < this.startTime) {
        this.endTime = this.startTime + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
